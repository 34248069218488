// Setup CBDS
$cbds-fontUrl: "../../node_modules/@cbds/cbds-fonts/dist/fonts";
@import "@cbds/cbds-components/dist/scss/tokens/_all";
@import "@cbds/cbds-components/dist/scss/globals/_all";

// CBDS-independent display utility class
.olb-u-display__none {
  display: none;
}

// Scope styles to the OLB initialization only
.olb-c-olbRootInit {
  // Import only CBDS components that will be used
  @import "@cbds/cbds-components/dist/components/cbds-components/cbds-button/cbds-button";
  @import "@cbds/cbds-components/dist/components/cbds-components/cbds-icon/cbds-icon";
  @import "@cbds/cbds-components/dist/components/cbds-components/cbds-logo/cbds-logo";
  @import "@cbds/cbds-components/dist/components/cbds-components/cbds-spinner/cbds-spinner";

  background-color: $cbds-tkn-colorBrand__white;
  font-family: $cbds-tkn-fontFamily__firaSans;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  line-height: 1.5; /* consistent with what global bootstrap is doing to avoid shifting */
  text-align: center;
  opacity: 0;
  z-index: $cbds-tkn-zIndex__topOfTheWorld;

  // Fade loading overlay
  transition: opacity 0.15s linear;
  @media (prefers-reduced-motion: reduce) {
    // Transition that does not display as a fade
    // Aligned with cbds-fade behavior, but will fire a `transitionend` event
    transition: opacity 0.01s linear;
  }

  /*
    Avoid content shifting when loading is complete due to scrollbar appearing.
    See https://stackoverflow.com/a/30293718/158740
  */
  padding-left: calc(100vw - 100%);

  &--initializing {
    opacity: 1 !important;
  }

  &__logo {
    margin-top: $cbds-tkn-space__1x * 30 !important;
    vertical-align: middle;
  }

  &__loadingText {
    margin: $cbds-tkn-space__4x $cbds-tkn-space__0;
    font-size: $cbds-tkn-fontSize__30;
    font-weight: $cbds-tkn-fontWeight__normal;
    line-height: $cbds-tkn-unit__lineHeight * 5 + $cbds-tkn-space__halfX;
    color: $cbds-tkn-textColor__primary;
  }

  &__spinner {
    margin: auto;
  }

  &__loaderMargin {
    margin-top: $cbds-tkn-space__8x;
  }

  &__errorIcon {
    margin-top: $cbds-tkn-space__4x;
    color: $cbds-tkn-color__red;
  }

  &__errorText {
    margin-top: $cbds-tkn-space__1x;
    color: $cbds-tkn-textColor__quat;
  }

  &__logoutButton {
    margin-top: $cbds-tkn-space__1x !important;
  }
}
